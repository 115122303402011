"use client";
import Image from "next/image";
import React from "react";
import ProfileSVG from "../SVGIcons/ProfileSVG";

type Props = {
  startupLink?: string;
  customClass?: string;
  alt: string;
  quality?: number | undefined;
  isPriority?: boolean | undefined;
};

export default function ProfileImage({
  startupLink,
  customClass,
  alt,
  quality = undefined,
  isPriority = undefined,
}: Props) {
  function renderImageWithSource(src: string | undefined) {
    if (src == undefined || src.length == 0)
      return <ProfileSVG className="mx-auto my-auto w-full p-2" />;
    return (
      <Image
        priority={isPriority}
        className={`h-full w-full ${customClass}`}
        quality={quality}
        // loader={myLoader}
        src={src}
        alt={alt}
        width="0"
        height="0"
        sizes="(max-width: 768px) 100vw,
(max-width: 1200px) 50vw,
33vw"
      />
    );
  }

  return renderImageWithSource(startupLink);
}
