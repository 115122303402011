import { CustomError } from "./CustomErrors";

export enum PresetErrorCodes {
  NO_USERNAME = "no_username",
  NAME_TAKEN = "name_taken",
  UNKNOWN = "unknown",
}

export class PresetError extends CustomError<PresetErrorCodes> {
  constructor(code: PresetErrorCodes, message = "error occurred") {
    super(message, code);
    Object.setPrototypeOf(this, PresetError.prototype);
  }
}
