export class CustomError<T extends string> extends Error {
  public code: T;

  constructor(message: string, code: T) {
    super(message);
    this.code = code;
    this.name = code;
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  public static mapErrorStringToEnum<T extends string>(
    errorString: string,
    enumObject: { [key: string]: T }
  ): T | undefined {
    const enumValues = Object.values(enumObject);
    const matchingEnum = enumValues.find((value) => value === errorString);

    return matchingEnum;
  }
}
