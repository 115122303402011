"use client";
import { Toaster } from "react-hot-toast";

type Props = {};

export default function ToasterContainer({}: Props) {
  return (
    <div>
      <Toaster />
    </div>
  );
}
