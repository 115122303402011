export type SplitText = {
  input: string;
  isToken: boolean;
};
export function countWords(str: string): number {
  return str.trim().split(/\s+/).length;
}

export function removeAllWhitespace(str: string): string {
  return str.replace(/\s+/g, "");
}

export function splitTextWithTokens(input: string): SplitText[] {
  const separators = ["[", "]", "{", "}", "<", ">"];
  const substrings: SplitText[] = [];

  const separatorsOpen = ["[", "{", "<"];
  const separatorsClose = ["]", "}", ">"];

  let currentSubstring = "";
  var isToken = false;
  for (const char of input) {
    if (separators.includes(char)) {
      if (currentSubstring) {
        substrings.push({ input: currentSubstring.trim(), isToken: isToken });
        currentSubstring = "";
      }
      currentSubstring += char;
      substrings.push({ input: currentSubstring.trim(), isToken: isToken });
      currentSubstring = "";
    } else {
      currentSubstring += char;
    }
    if (separatorsOpen.includes(char)) {
      isToken = true;
    }
    if (separatorsClose.includes(char)) {
      isToken = false;
    }
  }

  if (currentSubstring) {
    substrings.push({ input: currentSubstring.trim(), isToken: isToken });
  }

  return substrings.filter((x) => {
    return x.input.length != 0 && separators.indexOf(x.input) == -1;
  });
}

export function hideEmail(email: string): string {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    return email; // Return the email if "@" is not found
  }

  const [username, domain] = [email.slice(0, atIndex), email.slice(atIndex)];
  const emailHideCharCount = Math.min(4, username.length / 2);
  const hiddenUsername =
    username.substring(0, emailHideCharCount) +
    "*".repeat(username.length - emailHideCharCount);

  const domainHideCharCount = Math.min(4, domain.length / 2);
  const hiddenDomain =
    domain.substring(0, 3) + "*".repeat(domain.length - domainHideCharCount);
  const hiddenEmail = hiddenUsername + hiddenDomain;

  return hiddenEmail;
}

export function timeAgoFromMilliSecondsSinceEpoch(timestamp: number) {
  let value;
  const diff = (new Date().getTime() - timestamp) / 1000;
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  const rtf = new Intl.RelativeTimeFormat();

  if (years > 0) {
    value = rtf.format(0 - years, "year");
  } else if (months > 0) {
    value = rtf.format(0 - months, "month");
  } else if (days > 0) {
    value = rtf.format(0 - days, "day");
  } else if (hours > 0) {
    value = rtf.format(0 - hours, "hour");
  } else if (minutes > 0) {
    value = rtf.format(0 - minutes, "minute");
  } else {
    if (diff <= 1) {
      value = "Just now";
    } else {
      value = rtf.format(0 - diff, "second");
    }
  }
  return value;
}

export function timeAgoFromDate(date: Date) {
  return timeAgoFromMilliSecondsSinceEpoch(date.getTime());
}

export function timeAgoFromISODateString(dateISO: string) {
  return timeAgoFromDate(new Date(dateISO));
}

export function timeUntilFromMilliSecondsSinceEpoch(timestamp: number): string {
  const now = new Date().getTime();
  const diff = (timestamp - now) / 1000;

  if (diff <= 0) {
    return "";
  }

  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30.44); // Average month length
  const years = Math.floor(months / 12);

  const rtf = new Intl.RelativeTimeFormat("en", { style: "long" });

  if (years > 0) {
    return rtf.format(years, "year");
  } else if (months > 0) {
    return rtf.format(months, "month");
  } else if (days > 0) {
    return rtf.format(days, "day");
  } else if (hours > 0) {
    return rtf.format(hours, "hour");
  } else if (minutes > 0) {
    return rtf.format(minutes, "minute");
  } else {
    if (diff < 10) {
      return "Any moment now!";
    } else {
      return rtf.format(Math.floor(diff), "second");
    }
  }
}

export function timeUntilFromDate(date: Date) {
  return timeUntilFromMilliSecondsSinceEpoch(date.getTime());
}

export function timeUntilFromISODateString(dateISO: string) {
  return timeUntilFromDate(new Date(dateISO));
}

export function fromISOToLocale(isoDate: string) {
  return new Date(isoDate).toLocaleString();
}

export function ISODateExpired(ISODateString: string) {
  const givenDate = new Date(ISODateString);
  const currentDate = new Date();

  return givenDate < currentDate;
}
