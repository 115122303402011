"use client";
import ThemeButton from "@/components/ui/Theming/ThemeButton";
import { removeCurrentUserCachedData } from "@/hooks/QueryHooks/ReactQueryCachedDataManager";
import { useFetchCurrentUserQuery } from "@/hooks/QueryHooks/ReactQueryWrapperhooks";
import { IBackendManagerInterface } from "@/utils/BackendManager";
import { getLoginPath, getSignupPath } from "@/utils/NavigationUtils";
import { hideEmail } from "@mltask/core/Utils/StringHelper";
import { usePathname } from "next/navigation";
import React, { useEffect } from "react";
import NavigationProfileDropdown from "./NavigationProfileDropdown";
type Props = { loggedInUser: boolean };

var BackendManager: IBackendManagerInterface;
import("@/utils/BackendManager").then((lib) => {
  BackendManager = lib.default;
});

export default function NavigationBarButtons({ loggedInUser }: Props) {
  useEffect(() => {
    BackendManager.addSignInListener(() => {
      loggedInUser = true;
      refetch();
    });
    BackendManager.addSignOutListener(() => {
      removeCurrentUserCachedData();
    });
  }, []);

  const {
    data: fetchedUser,
    refetch,
    isFetching,
    isLoading,
  } = useFetchCurrentUserQuery(loggedInUser);

  const pathname = usePathname();
  function buildUserName() {
    if (fetchedUser?.username) return "@" + fetchedUser?.username;
    else if (fetchedUser?.name) return fetchedUser?.name;
    else if (fetchedUser?.email) return hideEmail(fetchedUser?.email);
    return "";
  }

  function debugSize() {
    return (
      <div className="right-0 top-0 mx-2 flex h-6 w-6 items-center justify-center rounded-full bg-gray-600  p-3 font-mono text-xs text-white sm:bg-pink-500 md:bg-orange-500 lg:bg-green-500 xl:bg-blue-500 2xl:bg-cyan-300">
        <div className="block  sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">
          al
        </div>
        <div className="hidden sm:block  md:hidden lg:hidden xl:hidden 2xl:hidden">
          sm
        </div>
        <div className="hidden sm:hidden md:block  lg:hidden xl:hidden 2xl:hidden">
          md
        </div>
        <div className="hidden sm:hidden md:hidden lg:block  xl:hidden 2xl:hidden">
          lg
        </div>
        <div className="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden">
          xl
        </div>
        <div className="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block">
          2xl
        </div>
      </div>
    );
  }
  function notificationIcon() {
    return (
      <div>
        <button className="btn btn-circle btn-ghost">
          <div className="indicator">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            <span className="badge indicator-item badge-primary badge-xs"></span>
          </div>
        </button>
      </div>
    );
  }

  function renderSignupSection() {
    return (
      // <div className="hidden lg:flex lg:flex-1 lg:justify-end">
      <div className=" p-1 lg:flex lg:flex-1 lg:justify-end">
        <a
          href={`${getLoginPath()}`}
          className=" text-sm font-semibold leading-6 transition-all duration-200  hover:-mr-1"
        >
          Log in <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
      // <div className=" flex space-x-2">
      //   <Link className="btn-secondary btn-sm btn normal-case" href={`${getLoginPath()}`}>
      //     Login
      //   </Link>
      // </div>
    );
  }

  function renderLoginButton() {
    if (
      fetchedUser ||
      pathname == getLoginPath() ||
      pathname == getSignupPath() ||
      //this one to prevent the main login
      //remove after exiting beta
      pathname.includes("socialman") == false
    )
      return;
    return renderSignupSection();
  }

  function renderLoadingUser() {
    if (isFetching == false && isLoading == false) return;
    return (
      <div className="my-auto flex h-full ">
        <span className="loading loading-spinner loading-md text-secondary"></span>
      </div>
    );
  }

  function renderProfileDropdown() {
    if (isFetching || isLoading) return;
    if (fetchedUser == undefined) return;
    return (
      <NavigationProfileDropdown
        usernameOrEmail={buildUserName()}
        ppURL={fetchedUser.picture ?? ""}
      />
    );
  }

  return (
    <div className="flex-none gap-2">
      {/* {notificationIcon()} */}
      {/* <ThemeSwitch></ThemeSwitch> */}
      <ThemeButton></ThemeButton>
      {renderLoginButton()}
      {renderLoadingUser()}
      {renderProfileDropdown()}
    </div>
  );
}
