import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/app/providers.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/components/analytics/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/components/ui/CookiesConsent/CookieConsentComponent.js.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/components/ui/Navigation/NavigationBarButtons.tsx");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/components/ui/Theming/Logo.tsx");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientContext","QueryClientProvider","useQueryClient"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/next/font/google/target.css?{\"path\":\"utils/Constants.ts\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/next/font/google/target.css?{\"path\":\"utils/Constants.ts\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-roboto-mono\",\"display\":\"swap\"}],\"variableName\":\"roboto_mono\"}");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/next/font/google/target.css?{\"path\":\"utils/Constants.ts\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/Users/bassemyoussef/WebDev/DTask/packages/web/next13frontend/node_modules/vanilla-cookieconsent/dist/cookieconsent.css");
